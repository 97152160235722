*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-size: 0.5rem;
  height: 100%;
  -webkit-tap-highlight-color: transparent;
}

body {
  background: #dcb687 url('../images/background.jpg');
  color: #fcfcfc;
  display: flex;
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 1.2rem;
  height: 100%;
}

.clickable {
  cursor: pointer;
}

.hidden {
  display: none;
}

.container {
  margin: auto;
  padding: 2rem;
}

.simon {
  background-color: #303030;
  border-radius: 50%;
  box-shadow: 0 0 1.5rem #171717;
  height: 90vw;
  position: relative;
  width: 90vw;
}

.btn {
  border: 2.5rem solid #303030;
  height: 50%;
  position: absolute;
  width: 50%;
}

.green.btn {
  background-color: #0ab552;
  border-bottom-width: 1.25rem;
  border-right-width: 1.25rem;
  border-top-left-radius: 100%;
}

.green.btn.lit {
  background-color: #57ff9f;
}

.red.btn {
  background-color: #e6281d;
  border-bottom-width: 1.25rem;
  border-left-width: 1.25rem;
  border-top-right-radius: 100%;
  left: 50%;
}

.red.btn.lit {
  background-color: #ff756a;
}

.yellow.btn {
  background-color: #cec92e;
  border-bottom-left-radius: 100%;
  border-right-width: 1.25rem;
  border-top-width: 1.25rem;
  top: 50%;
}

.yellow.btn.lit {
  background-color: #ffff7b;
}

.blue.btn {
  background-color: #005bc0;
  border-bottom-right-radius: 100%;
  border-left-width: 1.25rem;
  border-top-width: 1.25rem;
  left: 50%;
  top: 50%;
}

.blue.btn.lit {
  background-color: #4da8ff;
}

.center {
  background-color: #303030;
  border-radius: 50%;
  height: 50%;
  left: 25%;
  position: absolute;
  top: 25%;
  width: 50%;
}

.heading {
  font-family: 'Monoton', cursive;
  font-size: 4rem;
  margin-top: 20%;
  text-align: center;
}

.ctrl {
  float: left;
  height: 30%;
  margin-top: 5%;
  position: relative;
  text-align: center;
  width: 25%;
}

.ctrl-text {
  bottom: 0;
  left: 0;
  position: absolute;
  text-transform: uppercase;
  width: 100%;
}

.ctrl-btn {
  background: none;
  border: 0.2rem solid #fcfcfc;
  border-radius: 50%;
  cursor: pointer;
  margin-top: 0.75rem;
  outline: none;
  padding: 1rem;
}

.ctrl-btn:active {
  transform: translateY(0.15rem);
}

.ctrl-light {
  background-color: #340000;
  border: 0.2rem solid #340000;
  border-radius: 50%;
  height: 1rem;
  position: absolute;
  right: 0;
  top: 0;
  width: 1rem;
}

.ctrl-light.lit {
  background-color: #cd0f04;
}

.count-ctrl {
  margin-left: 10%;
}

.screen {
  background-color: #340000;
  border-radius: 0.75rem;
  color: #4d0000;
  padding: 0.4rem;
}

.screen.lit {
  color: #cd0f04;
}

.screen-text {
  font-family: 'VT323', monospace;
  font-size: 3.2rem;
}

.start-ctrl {
  margin-left: 5%;
}

.start-ctrl .ctrl-btn {
  background-color: #e6281d;
}

.strict-ctrl .ctrl-btn {
  background-color: #cec92e;
}

.power-ctrl {
  align-items: center;
  display: flex;
  height: 2rem;
  justify-content: center;
  margin-top: 7%;
  width: 100%;
}

.power-ctrl .ctrl-text {
  position: relative;
  width: initial;
}

.switch {
  display: inline-block;
  height: 2rem;
  margin: 0 1rem;
  position: relative;
  width: 3.2rem;
}

.slider {
  background-color: #171717;
  bottom: 0;
  cursor: pointer;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: 0.4s;
}

.slider::before {
  background-color: #e6281d;
  bottom: 0.4rem;
  content: '';
  height: 1.2rem;
  left: 0.4rem;
  position: absolute;
  transition: 0.4s;
  width: 1.2rem;
}

.power-btn:checked+.slider::before {
  transform: translateX(1.2rem);
}

@media (min-width: 30.625em) {
  html {
    font-size: 62.5%;
  }

  .simon {
    height: 45rem;
    width: 45rem;
  }
}
